.cashOutPage .box{
  background-color: #bbb;
  width: 100%;
  height: 260px;
  border-radius: 15px;
  margin-top: 20px;
  padding: 30px 0;
  text-align: center;
}
.cashOutPage .getCash{
  background-color: #ffe949;
  padding: 10px 25px;
  border: none;
  border-radius: 15px;
}
.cashOutPage .cashRecord p{
  display: inline-block;
  margin: 0;
  vertical-align: 5px;
}
.cashOutPage .cashRecord{
  font-size: 18px;
  color: #713ce2;
  text-align: center;
  margin-top: 150px;
}